import { reactive, Ref, ref, watchEffect } from "vue"
import { ParentId, ParentTask, Task, WeekDay } from "@/models/Task"
import TasksRequest from "@/components/tasks/types/TaskRequest"
import TaskService from "@/services/TaskService"
import OverdueTasksRequest from "@/components/tasks/types/OverdueTasksRequest"
import { TaskAndChildren } from "@/components/tasks/types/TaskAndChildren"
import {
  between,
  convertToTaskAndChildrenInstances,
  splitOverWeek,
} from "@/components/tasks/hooks/fetch-task-utils"
import { isBefore, isSameDay } from "date-fns"
import { SelectedTask } from "@/models/SelectedTask"
import { createInterval } from "@/components/tasks/util/Utils"

export function useFetchTasks(
  request: Ref<TasksRequest>,
  overdueRequest: Ref<OverdueTasksRequest>,
  selectedId: Ref<string>,
  now: Date,
  weekScope?: Ref<WeekDay[] | null>
) {
  const taskService = new TaskService()
  const tasks = ref<TaskAndChildren[]>([])
  const error = ref(null)
  const week = ref<Map<string, TaskAndChildren[]>>()
  const isLoading = ref(false)
  const nowNormalized = new Date(now.getFullYear(), now.getMonth(), now.getDate())

  const refresh = async () => {
    await fetchData()
  }

  const fetchData = async () => {
    isLoading.value = true
    try {
      const interval = weekScope ? createInterval(weekScope.value) : null
      const data = await taskService.getTasks(request.value)
      const parentTasks = data.filter((task: Task) => task.parent === null)
      const orphans = data.filter((task: any) => task.parent !== null) as any[]

      // Only execute for Week view
      const overdue = request.value.isScoped() ? await fetchAndFilterOverdueTasks(interval) : []

      await mapOverdueTasksToParents(overdue, parentTasks, interval, orphans)

      const mappedDataSet = convertToTaskAndChildrenInstances(
        Array.from(parentTasks),
        selectedId.value,
        now
      )

      await fetchAndAttachChildren(mappedDataSet)

      if (request.value.isScoped()) {
        week.value = splitOverWeek(mappedDataSet, interval, weekScope.value, nowNormalized)
        tasks.value = reactive(Array.from(week.value.values()).flatMap((arr) => arr))
      } else {
        tasks.value = mappedDataSet
      }
    } catch (e) {
      error.value = e
    } finally {
      isLoading.value = false
    }
  }

  // If not TODAY, filter out the overdue items that don't belong to the given interval
  const fetchAndFilterOverdueTasks = async (interval: Interval) => {
    let overdue = (await fetchOverdueTasks()) as any[]

    if (isSameDay(now, interval.start)) {
      overdue = overdue.filter((od) => isBefore(new Date(od.plannedDate), interval.start))
    } else {
      overdue = overdue.filter((od) => between(new Date(od.plannedDate), interval))
    }

    return overdue.sort(overdueComparator)
  }

  const mapOverdueTasksToParents = async (
    overdue: any[],
    parentTasks: Task[],
    interval: Interval,
    orphans: any[]
  ) => {
    const overdueOrphans = [] as any[]

    overdue.forEach((it) => {
      const parentId = it.parent ? it.parent.parent.id : null

      // an overdue parent
      if (!parentId && !parentTasks.find((pt) => pt.id === it.id)) {
        parentTasks.push(it)
      } else if (parentId) {
        overdueOrphans.push(it)
      }
    })

    if (overdueOrphans.length > 0) {
      await appendParentsForOrphans(overdueOrphans, parentTasks)
    }

    // If today is NOT part of the week scope, attach parents of orphans
    if (interval && !between(now, interval) && orphans.length > 0) {
      await appendParentsForOrphans(orphans, parentTasks)
    }
  }

  const appendParentsForOrphans = async (orphans: any[], parentTasks: Task[]) => {
    //Prevent double fetches
    const ids = new Set<string>()
    for (let orphan of orphans) {
      ids.add(orphan.parent.parent.id)
    }

    const parents = new Map<string, Task>()
    const parentPromises = Array.from(ids).map(async (id) => {
      if (!parents.has(id) && !parentTasks.find((pt) => pt.id === id)) {
        const parent = await taskService.getTaskById(id)
        parents.set(parent.id, parent)
      }
    })

    await Promise.all(parentPromises)
    parentTasks.push(...[].concat(...parents.values()))
  }

  const fetchOverdueTasks = async (): Promise<Task[]> => {
    const overdue = await taskService.getOverdueTasks(overdueRequest.value)
    overdue.forEach((item: any) => {
      item.selected = item.id === selectedId.value
    })
    return overdue
  }

  const fetchAndAttachChildren = async (parentTasks: TaskAndChildren[]): Promise<void> => {
    const promises = parentTasks
      .filter((p) => p.task.numberOfChildren > 0)
      .map(async (parent) => {
        const children = await taskService.getSubTasks(parent.task.id)
        parent.children = []
        children.forEach((child) => {
          const newInstance = SelectedTask.from(child, false)
          newInstance.parent = new ParentTask(
            child.parent.sequenceNumber,
            new ParentId(parent.task.id)
          )
          parent.children.push(newInstance)
        })
      })

    return Promise.all(promises).then(() => {})
  }

  const overdueComparator = (a: any, b: any) => {
    if (a.parent === null && b.parent !== null) {
      return -1
    } else if (a.parent !== null && b.parent === null) {
      return 1
    }

    if (a.parent && b.parent) {
      const aParentId = a.parent.parent.id
      const bParentId = b.parent.parent.id
      if (aParentId !== bParentId) {
        return aParentId - bParentId
      }
    }

    const seqA = a.parent ? a.parent.sequenceNumber : 0
    const seqB = b.parent ? b.parent.sequenceNumber : 0
    return seqA - seqB
  }

  watchEffect(() => {
    fetchData()
  })

  return { tasks, week, refresh, error, isLoading }
}
