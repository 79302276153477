export default class OverdueTasksRequest {
  constructor(
    public createdByUserId: string | null,
    public assigneeIds: string[] | null,
    public watcherIds: string[] | null,
    public organizationId: string | null,
    public computerId: string | null,
    public parentTaskId: string | null
  ) {}

  public static byWeekInstance(employeeIds: string[]) {
    return new OverdueTasksRequest(null, employeeIds, null, null, null, null)
  }

  public static byOrganizationInstance(organizationId: string) {
    return new OverdueTasksRequest(null, null, null, organizationId, null, null)
  }

  toParameterList() {
    
    let url = new URLSearchParams()

    if (this.createdByUserId) {
      url.append("createdByUserId", this.createdByUserId)
    }

    if (this.organizationId) {
      url.append("organizationId", this.organizationId)
    }

    if (this.computerId) {
      url.append("computerId", this.computerId)
    }

    if (this.parentTaskId) {
      url.append("parentTaskId", this.parentTaskId)
    }

    if (this.assigneeIds && this.assigneeIds.length) {      
      this.assigneeIds.forEach((id: string, index: number) => {        
        url.append("assigneeId", id)
      })
    }

    if (this.watcherIds && this.watcherIds.length) {      
      this.watcherIds.forEach((id: string, index: number) => {
        url.append("watcherId", id)
      })
    }

    return "?" + url.toString()
  }
}
