import TaskService from "@/services/TaskService"
import { ref } from "vue"
import { showErrorToast } from "@/helpers/ToastHelper"
import { ToastServiceMethods } from "primevue/toastservice"

export default function usePostToWalterErp(service: TaskService) {
  const updating = ref(false)

  function postToErp(id: string, toast: ToastServiceMethods, callback?: () => void) {
    updating.value = true

    service
      .postTaskWalterpBridge(id)
      .then(() => {
        console.log("task posted successfully to Walterp-Bridge")

        if (callback) {
          callback()
        }
        updating.value = false
      })
      .catch((error) => showErrorToast(error, toast, updating))
  }

  return { postToErp, updating }
}
