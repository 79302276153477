export const taskCategories: string[] = [
  "LETTER",
  "MAIL",
  "OFFICE",
  "PARCEL",
  "PHONE_CALL",
  "TEAMVIEWER",
  "VISIT",
  "WHATSAPP",
]
