import TaskService from "@/services/TaskService"
import { ref } from "vue"
import { ParentId, ParentTask, Task } from "@/models/Task"
import { ToastServiceMethods } from "primevue/toastservice"
import { showErrorToast } from "@/helpers/ToastHelper"
import { SelectedTask } from "@/models/SelectedTask"
import { useStore } from "vuex"
import { TaskAssignee } from "@/models/TaskAssignee"

export default function useAddSubTask(service: TaskService) {
  const updating = ref(false)
  const store = useStore()

  async function addSubTask(
    task: Task,
    parentTaskId: string,
    organizationId: string,
    toast: ToastServiceMethods,
    numOfChildren: number,
    callback?: (newTask: SelectedTask) => void
  ) {
    updating.value = true

    task.id = null
    task.labels = []

    try {
      const sequenceNumber = numOfChildren < 0 ? 100 : 100 * (numOfChildren + 1)
      const newTask = await service.postTask(task, organizationId, null)      
      await service.addSubTask(newTask.id, parentTaskId, sequenceNumber)
      if (callback) {
        newTask.parent = new ParentTask(sequenceNumber, new ParentId(parentTaskId))
        const selTask = SelectedTask.from(newTask, false);
        selTask.assignees.push({seen: false, user: store.getters.getUser})
        callback(selTask)
      }
      updating.value = false
    } catch (error) {
      showErrorToast(error, toast, updating)
    }
  }

  return { addSubTask, updating }
}
