import { ToastServiceMethods } from "primevue/toastservice"
import { Ref } from "vue"

export function showErrorToast(error: any, toast: ToastServiceMethods, saving: Ref<boolean>) {
  console.error(error)

  saving.value = false
  toast.add({
    severity: "error",
    summary: error.error || "An Error Occurred",
    detail: error.message,
    life: 5000,
  })
}
