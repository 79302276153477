import TaskService from "@/services/TaskService"
import { ref } from "vue"
import { showErrorToast } from "@/helpers/ToastHelper"
import { ToastServiceMethods } from "primevue/toastservice"

export default function useRemoveLabel(service: TaskService) {
  const updating = ref(false)

  function removeLabel(
    id: string,
    labelId: string,
    toast: ToastServiceMethods,
    callback?: () => void
  ) {
    updating.value = true

    service
      .deleteLabelFromTask(id, labelId)
      .then(() => {
        if (callback) {
          callback()
        }
        updating.value = false
      })
      .catch((error) => showErrorToast(error, toast, updating))
  }

  return { removeLabel, updating }
}
