import axios from "axios"
import { useStore } from "vuex"
import ResultSet from "@/models/ResultSet"

export default class TaskLabelService {
  store: any

  constructor() {
    this.store = useStore()
  }

  getTaskLabelById(labelId: string) {
    let url: string = "tasklabels/" + labelId
    return axios.get(url).then((response) => response.data)
  }

  async getAllTaskLabels() {
    const url = "tasklabels"
    if (!this.store.getters.hasTaskLabelsData(url)) {
      const res = await axios.get(url)
      await this.store.commit("setTaskLabels", {
        url,
        data: res.data.sort((a: any, b: any) => (a.ranking > b.ranking ? -1 : 1)),
      })
    }
    return this.store.getters.getTaskLabelsData(url)
  }

  postTaskLabel(label: any) {
    return axios.post("tasklabels", label).then((response) => response.data)
  }

  editTaskLabel(labelId: string, label: any) {
    let url: string = "tasklabels/" + labelId
    return axios.put(url, label).then((response) => response.data)
  }

  deleteTaskLabel(labelId: string) {
    let url: string = "tasklabels/" + labelId
    return axios.delete(url).then((response) => response.data)
  }

  deleteTaskLabels(labelIds: string[]) {
    let url = "tasklabels"
    if (labelIds && labelIds.length) {
      labelIds.forEach((id: string, index: number) => {
        if (!index) {
          url += "?ids=" + id
        } else {
          url += "&ids=" + id
        }
      })
    }
    axios.delete(url).then((response) => response.data)
  }
}
