import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-024027a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label-all-states" }
const _hoisted_2 = {
  key: 1,
  class: "edit-label-container"
}
const _hoisted_3 = { class: "edit-label-action-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$props.label.isEditing || $props.connectedToTask)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "label",
          style: _normalizeStyle('background-color:' + $props.label.code + ';')
        }, [
          _createElementVNode("p", null, _toDisplayString($props.label.alias), 1),
          ($props.connectedToTask)
            ? (_openBlock(), _createBlock(_component_mdicon, {
                key: 0,
                name: "close",
                size: 18,
                onClick: $setup.deleteLabelFromTask
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ], 4))
      : _createCommentVNode("", true),
    ($props.label.isEditing && !$props.connectedToTask)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_InputText, {
            modelValue: $setup.newLabelAlias,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.newLabelAlias) = $event))
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Button, {
              disabled: $props.label.alias === $setup.newLabelAlias,
              class: "p-button-rounded p-button-text",
              icon: "pi pi-save",
              onClick: $setup.editLabel
            }, null, 8, ["disabled", "onClick"]),
            _createVNode(_component_Button, {
              class: "p-button-rounded p-button-text",
              icon: "pi pi-times",
              onClick: $setup.cancelEditLabel
            }, null, 8, ["onClick"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}