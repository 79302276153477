import { ParentId, ParentTask, Task, WeekDay } from "@/models/Task"
import { TaskAndChildren } from "@/components/tasks/types/TaskAndChildren"
import { format, isAfter, isBefore, isSameDay } from "date-fns"
import { reactive } from "vue"
import { SelectedTask } from "@/models/SelectedTask"

const FORMAT = "yyyy-MM-dd"

export const convertToTaskAndChildrenInstances = (
  theTasks: Task[],
  selectedId: string,
  now: Date
): TaskAndChildren[] => {
  const isTaskOverdue = (task: Task): boolean => {
    const planned = new Date(task.plannedDate)
    return !task.completionTime && isBefore(planned, now) && !isSameDay(planned, now)
  }

  return theTasks.map((task: any) => {
    const isSelected = task.id === selectedId
    const parent = task.parent
      ? new ParentTask(task.parent.sequenceNumber, new ParentId(task.parent.parent.id))
      : null
    const selectedTask = SelectedTask.from(task, isSelected)
    selectedTask.parent = parent
    const instance = reactive(new TaskAndChildren(selectedTask, []))

    if (isTaskOverdue(task)) {
      instance.task.overdue = true
    }

    return instance
  })
}

export const splitOverWeek = (
  mappedDataSet: TaskAndChildren[],
  interval: Interval,
  weekScope: WeekDay[],
  now: Date
): Map<string, TaskAndChildren[]> => {
  if (weekScope) {
    const tempWeek = reactive(new Map<string, TaskAndChildren[]>())
    weekScope.forEach((day: WeekDay) => {
      tempWeek.set(day.date, reactive([]))
    })

    // destructure parent and children
    const flatList = splitOffChildrenWithDiffPlannedDate(mappedDataSet, interval, now)

    distributeOverWeek(flatList, tempWeek, interval)

    return tempWeek
  }
}

export function distributeOverWeek(
  items: TaskAndChildren[],
  week: Map<string, TaskAndChildren[]>,
  interval: Interval
) {
  items.forEach((item) => {
    const completionDate = item.task.isCompleted()
      ? format(new Date(item.task.completionTime), FORMAT)
      : null
    const diffChildDate = item.getDifferentiatingChildDatesWithin(interval)
    const dateKey = diffChildDate
      ? diffChildDate
      : item.task.isCompleted()
      ? completionDate
      : item.task.plannedDate

    let weekDayTasks

    // Completed Task use completionTime iso plannedDate and so can be greater
    if (completionDate && !week.has(dateKey)) {
      return //skip this item
    }

    if (week.has(dateKey)) {
      weekDayTasks = week.get(dateKey)
      weekDayTasks.push(reactive(item))
    } else {
      weekDayTasks = week.get(format(interval.start, FORMAT))
      const parent = item.task.parent
      const foundParent = weekDayTasks.find((it) => it.task.id === parent?.parentId.id)
      if (foundParent) {
        if (!foundParent.children.find((c) => c.id === item.task.id)) {
          foundParent.children.push(item.task)
        }
      } else {
        weekDayTasks.push(item)
      }
    }
  })
}

function parentNotContainedInDataset(id: string, dataset: TaskAndChildren[]) {
  return !dataset.find((it) => it.task.id === id)
}

/*
Split children with another plannedDate to a copy of its parent
 */
export const splitOffChildrenWithDiffPlannedDate = (
  items: TaskAndChildren[],
  interval: Interval,
  now: Date
): TaskAndChildren[] => {
  const result: TaskAndChildren[] = reactive([])

  for (const taskAndChildren of items) {
    const { task: curTask, children } = taskAndChildren
    const reactiveInitialTask = reactive(curTask)
    const it = new TaskAndChildren(reactiveInitialTask, [])

    if (
      (taskAndChildren.children.length === 0 && taskAndChildren.task.parent === null) ||
      (taskAndChildren.task.parent !== null &&
        parentNotContainedInDataset(taskAndChildren.task.parent.parentId.id, items))
    ) {
      result.push(it)
    } else {
      // Group children by their planned date
      const childrenByDate = groupByPlannedDate(children, interval, now)

      // Create a new TaskAndChildren item for each unique planned date
      for (const [date, childrenWithDate] of childrenByDate.entries()) {
        if (date !== curTask.plannedDate) {
          const newTask = SelectedTask.from(curTask, false)
          const newTaskAndChildren = new TaskAndChildren(newTask, childrenWithDate, true)
          result.push(newTaskAndChildren)

          if (
            taskAndChildren.allChildrenPlannedOnOtherDates() &&
            curTask.plannedDate &&
            between(new Date(curTask.plannedDate), interval) &&
            !result.includes(it)
          ) {
            result.push(it)
          }
        } else {
          it.merged = true
          it.children.push(...childrenWithDate)
          result.push(it)
        }
      }

      if (
        taskAndChildren.task.plannedDate &&
        taskAndChildren.allChildrenPlannedOnOtherDates() &&
        between(new Date(taskAndChildren.task.plannedDate), interval) &&
        !result.includes(it)
      ) {
        result.push(it)
      }
    }
  }

  return result
}

// Create a new TaskAndChildren item for each unique planned date
function groupByPlannedDate(
  children: SelectedTask[],
  interval: Interval,
  now: Date
): Map<string, SelectedTask[]> {
  const result = new Map<string, SelectedTask[]>()
  for (const child of children) {
    const aDate = new Date(child.plannedDate)
    if (child.plannedDate && between(aDate, interval)) {
      if (!result.has(child.plannedDate)) {
        result.set(child.plannedDate, [])
      }
      result.get(child.plannedDate).push(child)
    } else if (
      child.plannedDate &&
      !isAfter(aDate, interval.end) &&
      format(interval.start, FORMAT) === format(now, FORMAT)
    ) {
      //Add under the first day of the interval
      const dateKey = format(interval.start, FORMAT)
      if (!result.has(dateKey)) {
        result.set(dateKey, [])
      }
      result.get(dateKey).push(child)
    }
  }
  return result
}

// Replacement for isWithinInterval, because it behaves incorrectly in the Browser!
export function between(value: Date, interval: Interval): boolean {
  if (typeof interval.end === "number" && typeof interval.start === "number") {
    return (
      value.getTime() >= (interval.start as number) && value.getTime() <= (interval.end as number)
    )
  } else {
    const val = value.getTime()
    const start = (interval.start as Date).getTime()
    const end = (interval.end as Date).getTime()

    return val >= start && val <= end
  }
}
