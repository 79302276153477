import { ref } from "vue"
import TaskService from "@/services/TaskService"
import { ServerError } from "@/utils/server-error"
import { showErrorToast } from "@/helpers/ToastHelper"
import { ToastServiceMethods } from "primevue/toastservice"

export default function useDeleteTask(taskService: TaskService) {
  const updating = ref(false)

  function deleteTask(id: string, toast: ToastServiceMethods, callback?: () => void) {
    updating.value = true
    taskService
      .deleteTask(id)
      .then(() => {
        if (callback) {
          callback()
        }
        updating.value = false
      })
      .catch((error: ServerError) => {
        showErrorToast(error, toast, updating)
      })
  }

  return { deleteTask, updating }
}
