import { SelectedTask } from "@/models/SelectedTask"
import MoveSubTaskEvent from "@/components/tasks/types/MoveSubTaskEvent"

export default function useMoveSubTask(emits: any) {
  const subTaskDragStartHandler = (e: DragEvent, subtask: SelectedTask) => {
    ;(e.target as HTMLElement).classList.add("dragging")

    const payload = {
      taskId: subtask.id,
      parentTaskId: subtask.parent.parentId.id,
      sequenceNumber: subtask.parent.sequenceNumber,
    } as MoveSubTaskEvent

    e.dataTransfer.effectAllowed = "move"
    e.dataTransfer.setData("text/plain", JSON.stringify(payload))
  }

  const subTaskDragEndHandler = (e: DragEvent) =>
    (e.target as HTMLElement).classList.remove("dragging")

  const subTaskDragOverHandler = (e: DragEvent) =>
    (e.target as HTMLElement).classList.add("dragover")

  const subTaskDragLeaveHandler = (e: DragEvent) =>
    (e.target as HTMLElement).classList.remove("dragover")

  const subTaskDropHandler = (e: DragEvent, targetTask: SelectedTask) => {
    const payload = JSON.parse(e.dataTransfer.getData("text/plain")) as MoveSubTaskEvent
    if (targetTask.id !== payload.taskId) {
      const targetSequence = targetTask.parent.sequenceNumber

      if (targetSequence < payload.sequenceNumber) {
        payload.sequenceNumber = targetSequence > 0 ? targetSequence - 1 : 0
      } else {
        payload.sequenceNumber = targetSequence + 1
      }

      ;(e.target as HTMLElement).classList.remove("dragover")
      emits("move-subtask", payload)
    }
  }
  return {
    subTaskDragStartHandler,
    subTaskDragEndHandler,
    subTaskDragOverHandler,
    subTaskDragLeaveHandler,
    subTaskDropHandler,
  }
}
