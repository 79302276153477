import { SelectedTask } from "@/models/SelectedTask"

export default function useSubTaskAssignee() {
  const subtaskAssigneeFirstname = (task: SelectedTask) => {   
    try{
      
      return task.assignees.length !== 0 ? task.assignees[0].user.firstName : null
    }catch(e){
      console.log(task) 
    }
  }

  const subtaskAssigneeLastname = (task: SelectedTask) => {    
    return task.assignees.length !== 0 ? task.assignees[0].user.lastName : null
  }

  return { subtaskAssigneeFirstname, subtaskAssigneeLastname }
}
