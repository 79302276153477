import TaskService from "@/services/TaskService"
import { ref } from "vue"
import { showErrorToast } from "@/helpers/ToastHelper"
import { ToastServiceMethods } from "primevue/toastservice"
import { Task } from "@/models/Task"

export default function useReopenTask(service: TaskService) {
  const updating = ref(false)

  async function reopenTask(
    id: string,
    toast: ToastServiceMethods,
    callback?: (task: Task) => void
  ) {
    updating.value = true

    try {
      await service.uncompleteTask(id)

      if (callback) {
        const task = await service.getTaskById(id)
        callback(task)
      }
      updating.value = false
    } catch (error) {
      showErrorToast(error, toast, updating)
    }
  }

  return { reopenTask, updating }
}
