import TaskService from "@/services/TaskService"
import { ref } from "vue"
import { Task } from "@/models/Task"
import { TaskAndChildren } from "@/components/tasks/types/TaskAndChildren"
import { ToastServiceMethods } from "primevue/toastservice"
import { format, isAfter, parse } from "date-fns"
import { showErrorToast } from "@/helpers/ToastHelper"

export default function useUpdateTask(service: TaskService) {
  const updating = ref(false)

  async function updateTask(
    parentItem: TaskAndChildren,
    parentTaskToUpdate: Task,
    toast: ToastServiceMethods,
    callback?: (updateTask: Task) => void
  ) {
    const invalidChildItems = parentItem.children.filter((child) => {
      if (!parentItem.task.deadline) return null
      const curChildPlannedDate = parse(child.plannedDate, "yyyy-MM-dd", new Date())
      const parentDeadline = new Date(parentItem.task.deadline)
      const after = isAfter(curChildPlannedDate, parentDeadline)
      return after ? child : null
    })

    if (parentTaskToUpdate.id === parentItem.task.id && invalidChildItems.length > 0) {
      toast.add({
        severity: "warn",
        summary: "Attention!",
        detail: `Following subtasks planned date exceeds the defined deadline (${format(
          new Date(parentItem.task.deadline),
          "dd/MM/yyyy"
        )}): ${invalidChildItems.map((i) => i.subject).join(", ")}`,
        life: 5000,
      })
    }

    try {
      updating.value = true
      const data = await service.editTask(parentTaskToUpdate.id, parentTaskToUpdate)
      if (callback) {
        callback(data)
      }
      updating.value = false
      return data
    } catch (error) {
      showErrorToast(error, toast, updating)
    }
  }

  return { updateTask, updating }
}
