export default class TasksRequest {
  constructor(
    public createdByUserId: string | null,
    public assigneeIds: string[] | null,
    public watcherIds: string[] | null,
    public organizationId: string | null,
    public computerId: string | null,
    public parentTaskId: string | null,
    public plannedFrom: string | null,
    public plannedTo: string | null,
    public splitChildren: boolean = false
  ) {}

  public static byOrganizationInstance(organizationId: string | null): TasksRequest {
    return new TasksRequest(null, null, null, organizationId, null, null, null, null)
  }

  public static byWeekInstance(
    assigneeIds: string[] | null,
    plannedFrom: string | null,
    plannedTo: string | null
  ): TasksRequest {
    return new TasksRequest(null, assigneeIds, null, null, null, null, plannedFrom, plannedTo, true)
  }

  public toParameterList() {
    let url = new URLSearchParams()
    if (this.createdByUserId) {
      url.append("createdByUserId", this.createdByUserId)
    }

    if (this.organizationId) {
      url.append("organizationId", this.organizationId)
    }

    if (this.computerId) {
      url.append("computerId", this.computerId)
    }

    if (this.parentTaskId) {
      url.append("parentTaskId", this.parentTaskId)
    }

    if (this.plannedFrom) {
      url.append("plannedFrom", this.plannedFrom)
    }

    if (this.plannedTo) {
      url.append("plannedTo", this.plannedTo)
    }

    if (this.assigneeIds && this.assigneeIds.length) {
      this.assigneeIds.forEach((id: string) => {
        url.append("assigneeId", id)
      })
    }

    if (this.watcherIds && this.watcherIds.length) {
      this.watcherIds.forEach((id: string) => {
        url.append("watcherId", id)
      })
    }

    return "&" + url.toString()
  }

  isScoped() {
    return this.plannedFrom !== null && this.plannedTo !== null
  }
}
