import { Task } from "@/models/Task"
import { showErrorToast } from "@/helpers/ToastHelper"
import { TaskAndChildren } from "@/components/tasks/types/TaskAndChildren"
import { ConfirmationOptions } from "primevue/confirmationoptions"
import { ToastServiceMethods } from "primevue/toastservice"
import { Ref, ref } from "vue"
import TaskService from "@/services/TaskService"

interface ConfirmationProps {
  require: (option: ConfirmationOptions) => void
  close: () => void
}

export default function useCompleteTask(taskService: TaskService) {
  const updating = ref(false)

  async function completeTask(
    task: Task,
    tasks: TaskAndChildren[] | null,
    confirm: ConfirmationProps,
    toast: ToastServiceMethods,
    callback?: (refreshData: boolean, item: Task) => void
  ) {
    const item = tasks?.find((it) => it.task.id === task.id)
    const uncompletedChildren = item ? item.hasUncompletedChildren() : false

    let id: string = item ? item.task.id : null
    if (!id && item) {
      for (const item of tasks) {
        const childTask = item.children.find((child) => child.id === task.id)
        if (childTask) {
          id = childTask.id
          break
        }
      }
    }

    try {
      updating.value = true
      const refreshData = await validateAndCompleteTask(
        id || task.id,
        uncompletedChildren,
        confirm,
        updating,
        taskService
      )

      const theItem = await taskService.getTaskById(task.id)

      if (callback) {
        callback(refreshData, theItem)
      }
      updating.value = false
    } catch (error) {
      showErrorToast(error, toast, updating)
    }
  }

  async function validateAndCompleteTask(
    id: string,
    hasUncompletedChildren: boolean,
    confirm: ConfirmationProps,
    saving: Ref<boolean>,
    taskService: TaskService
  ): Promise<boolean> {
    if (id && hasUncompletedChildren) {
      return new Promise((resolve) => {
        confirm.require({
          icon: "pi pi-exclamation-triangle",
          message: "Completing this task will also complete ALL subtasks. Continue?",
          header: "Uncompleted subtasks found!",
          accept: async () => {
            saving.value = true
            await taskService.completeTask(id)
            saving.value = false
            resolve(true)
          },
          reject() {
            saving.value = false
            resolve(false)
          },
        })
      })
    } else {
      saving.value = true
      await taskService.completeTask(id)
      saving.value = false
      return Promise.resolve(false)
    }
  }

  return { completeTask, updating }
}
