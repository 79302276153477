
import FilterOverlay from "@/components/data/FilterOverlay.vue"
import { taskCategories } from "@/constants/TaskCategories"
import { taskPriorities } from "@/constants/TaskPriorities"
import { Label } from "@/models/Label"
import TaskLabelService from "@/services/TaskLabelService"
import { reactive, ref } from "vue"
import LabelComponent from "@/components/tasks/label/LabelComponent.vue"
import useTaskUtils from "@/utils/task"

export default {
  name: "TaskListFilter",
  components: { FilterOverlay, LabelComponent },
  props: {
    activeFilters: Array,
    organization: {
      type: Object,
      default: null,
    },
    companies: {
      type: Array,
      default: null,
    },
  },
  emits: ["reload"],
  setup(props: any, { emit }: any) {
    const taskLabelService = new TaskLabelService()
    const selectedCategories = ref()
    const selectedPriorities = ref()
    const selectedLabels = ref()
    const selectedCompanies = ref()
    const labelOptions = reactive([])
    const textField = ref()
    const timeout = ref(null)
    const { getTaskIcon } = useTaskUtils()

    getAllLabels()

    function getAllLabels() {
      if (props.organization) {
        taskLabelService.getAllTaskLabels().then((result: any) => {
          if (result && result.length) {
            result.forEach((label: string) => {
              labelOptions.push(label)
            })
          }
        })
      }
    }

    /*     function filterCategory(event: any) {
      let activeFiltersAfterRemove = props.activeFilters.filter(
        (activeFilter: any) => activeFilter.field !== "category"
      )
      props.activeFilters.length = 0
      activeFiltersAfterRemove.forEach((item: any) => {
        props.activeFilters.push(item)
      })

      if (event.value && event.value.length) {
        props.activeFilters.push({
          field: "category",
          type: "equals",
          compareValue: event.value,
          path: (row: any) => row["category"],
          operator: "=",
        })
      }

      emit("reload")
    } */

    function filterPriority(event: any) {
      let activeFiltersAfterRemove = props.activeFilters.filter(
        (activeFilter: any) => activeFilter.field !== "priority"
      )
      props.activeFilters.length = 0
      activeFiltersAfterRemove.forEach((item: any) => {
        props.activeFilters.push(item)
      })

      if (event.value && event.value.length) {
        props.activeFilters.push({
          field: "priority",
          type: "equals",
          compareValue: event.value,
          path: (row: any) => row["priority"],
          operator: "=",
        })
      }

      emit("reload")
    }

    function filterLabel(event: any) {
      let activeFiltersAfterRemove = props.activeFilters.filter(
        (activeFilter: any) => activeFilter.field !== "label"
      )
      props.activeFilters.length = 0
      activeFiltersAfterRemove.forEach((item: any) => {
        props.activeFilters.push(item)
      })

      if (event.value && event.value.length) {
        props.activeFilters.push({
          field: "label",
          type: "equals",
          compareValue: event.value.map((label: Label) => label.alias),
          path: (row: any) => row["labels"].map((label: any) => label.alias),
          operator: "=",
        })
      }

      emit("reload")
    }

    function filterText() {
      clearTimeout(timeout.value)
      timeout.value = setTimeout(() => {
        if (textField.value !== null && textField.value.trim().length > 0) {
          let existingTextField = props.activeFilters.find((filter: any) => filter.field === "text")
          if (existingTextField) {
            existingTextField.compareValue = textField.value
          } else {
            props.activeFilters.push({
              field: "text",
              type: "subString",
              compareValue: textField.value,
              path: (row: any) => row["subject"] + row["description"],
              operator: "=",
            })
          }
          emit("reload")
        } else {
          const idx = props.activeFilters.findIndex((f: any) => f.field === "text")
          props.activeFilters.splice(idx, 1)
          emit("reload")
        }
      }, 500)
    }

    function filterCompany(event: any) {
      let activeFiltersAfterRemove = props.activeFilters.filter(
        (activeFilter: any) => activeFilter.field !== "company"
      )
      props.activeFilters.length = 0
      activeFiltersAfterRemove.forEach((item: any) => {
        props.activeFilters.push(item)
      })

      if (event.value && event.value.length) {
        props.activeFilters.push({
          field: "company",
          type: "equals",
          compareValue: event.value.map((item: any) => item.id),
          path: (row: any) => row["organization"]["company"]["id"],
          operator: "=",
        })
      }

      emit("reload")
    }

    function resetFilters() {
      textField.value = ""
      selectedCategories.value = null
      selectedLabels.value = null
      selectedPriorities.value = null
      selectedCompanies.value = null
      props.activeFilters.length = 0
      emit("reload")
    }

    function resetSingleFilter(fieldString: string) {
      let activeFiltersAfterRemove = props.activeFilters.filter(
        (activeFilter: any) => activeFilter.field !== fieldString
      )
      props.activeFilters.length = 0
      activeFiltersAfterRemove.forEach((item: any) => {
        props.activeFilters.push(item)
      })
      emit("reload")
    }

    function companyHandler() {
      selectedCompanies.value = null
      resetSingleFilter("company")
    }

    function textHandler() {
      textField.value = ""
      resetSingleFilter("text")
    }

    function priorityHandler() {
      selectedPriorities.value = null
      resetSingleFilter("priority")
    }

    function labelHandler() {
      selectedLabels.value = null
      resetSingleFilter("label")
    }

    return {
      taskCategories,
      selectedCategories,
      textField,
      filterText,
      resetFilters,
      resetSingleFilter,
      selectedLabels,
      filterLabel,
      labelOptions,
      getTaskIcon,
      selectedPriorities,
      filterPriority,
      taskPriorities,
      selectedCompanies,
      filterCompany,
      companyHandler,
      textHandler,
      priorityHandler,
      labelHandler,
    }
  },
}
