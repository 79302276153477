import { between } from "@/components/tasks/hooks/fetch-task-utils"
import { SelectedTask } from "@/models/SelectedTask"

export class TaskAndChildren {
  public task: SelectedTask
  public children: SelectedTask[]
  public merged: boolean // to indicate if children were moved to dedicated weekday

  constructor(_task: SelectedTask, _children: SelectedTask[], _merged: boolean = false) {
    this.task = _task
    this.children = _children
    this.merged = _merged
  }

  setSelectedTask(_task: SelectedTask) {
    this.task = _task
  }

  hasUncompletedChildren(): boolean {
    return this.children
      ? !!this.children.some((child: SelectedTask) => !child.completionTime)
      : false
  }

  getDifferentiatingChildDatesWithin(interval: Interval): string {
    const differentiatingChild = this.children.find(
      (child) =>
        child.plannedDate !== this.task.plannedDate &&
        between(new Date(child.plannedDate), interval)
    )
    return differentiatingChild ? differentiatingChild.plannedDate : null
  }

  allChildrenPlannedOnOtherDates(): boolean {
    return this.children
      .filter((item) => item.plannedDate)
      .every((child) => child.plannedDate !== this.task.plannedDate)
  }

  // Checks if an ID matches the taskId or one of the child IDs
  matchesId(id: string): boolean {
    let matchFound = false

    if (this.task.id === id) {
      matchFound = true
    } else if (this.children && this.children.length > 0) {
      for (let i = 0; i < this.children.length && !matchFound; i++) {
        matchFound = this.children[i].id === id
      }
    }
    return matchFound
  }
}
