import { TaskAndChildren } from "@/components/tasks/types/TaskAndChildren"
import { Task } from "@/models/Task"
import Employee from "@/models/Employee"
import { format } from "date-fns"

export function sortItems(list: TaskAndChildren[], now: Date): TaskAndChildren[] {
  const comparator = (a: TaskAndChildren, b: TaskAndChildren): number => {
    const aTask = a.task
    const bTask = b.task

    if (!aTask.isCompleted() || !bTask.isCompleted()) {
      // Ensure completed items are last
      if (aTask.isCompleted() && !bTask.isCompleted()) return 1
      if (!aTask.isCompleted() && bTask.isCompleted()) return -1

      // Convert dates from strings to Date objects for comparison
      const deadlineA = aTask.deadline ? new Date(aTask.deadline) : null
      const deadlineB = bTask.deadline ? new Date(bTask.deadline) : null
      const plannedDateA = aTask.plannedDate ? new Date(aTask.plannedDate) : null
      const plannedDateB = bTask.plannedDate ? new Date(bTask.plannedDate) : null

      // 1. Priority "URGENT" first
      if (aTask.isPriority() && !bTask.isPriority()) return -1
      if (!aTask.isPriority() && bTask.isPriority()) return 1

      // 2. Items with expired deadline
      if (deadlineA && deadlineA < now && (!deadlineB || deadlineB >= now)) return -1
      if (deadlineB && deadlineB < now && (!deadlineA || deadlineA >= now)) return 1

      // 3. Items with deadline set to today
      const isToday = (date: Date | null): boolean =>
        date ? format(date, "yyyy-MM-dd") === format(now, "yyyy-MM-dd") : false

      if (isToday(deadlineA) && !isToday(deadlineB)) return -1
      if (!isToday(deadlineA) && isToday(deadlineB)) return 1

      // 4. Items with expired plannedDate
      if (plannedDateA && plannedDateA < now && (!plannedDateB || plannedDateB >= now)) return -1
      else if (plannedDateB && plannedDateB < now && (!plannedDateA || plannedDateA >= now))
        return 1
      else {
        //sort alphabetically
        return aTask.subject.localeCompare(bTask.subject)
      }
    } else {
      // 5. Completed items sorted on completionDate DESC
      const completionA = aTask.completionTime ? new Date(aTask.completionTime) : null
      const completionB = bTask.completionTime ? new Date(bTask.completionTime) : null
      if (completionA > completionB) return -1
      else if (completionB > completionA) return 1
      return 0
    }
  }

  return list.sort(comparator)
}

export function sortSplitTaskAndCompletedOnPlannedDate(list: TaskAndChildren[]): TaskAndChildren[] {
  const taskComparator = (a: TaskAndChildren, b: TaskAndChildren): number => {
    if (a.task.completionTime === null && b.task.completionTime !== null) {
      return -1 // a comes before b
    }
    if (a.task.completionTime !== null && b.task.completionTime === null) {
      return 1 // b comes before a
    }
    // If both have or don't have a completionTime, sort by creationDate
    const dateA = new Date(a.task.plannedDate)
    const dateB = new Date(b.task.plannedDate)
    return dateA.getTime() - dateB.getTime()
  }

  return list.sort(taskComparator)
}

export function sortChildBySequenceNumber(children: Task[]) {
  const taskComparator = (a: any, b: any) => a.parent.sequenceNumber - b.parent.sequenceNumber
  return children.sort(taskComparator)
}

export function sortByParent(items: Task[]): Task[] {
  const taskComparator = (a: Task, b: Task) => {
    if (a.parent === null && b.parent !== null) {
      return -1
    }
    if (a.parent !== null && b.parent === null) {
      return 1
    }
    return 0
  }
  return items.sort(taskComparator)
}

export function sortByPlannedDate(list: TaskAndChildren[]) {
  if (list.length > 0) {
    //Sort on planneddate
    list = list.sort(function (a: any, b: any) {
      let dateA = new Date(a.task.planneddate)
      let dateB = new Date(b.task.planneddate)

      if (dateA == null || dateB == null) {
        return 0
      }
      if (dateA > dateB) {
        return 1
      }
      if (dateA < dateB) {
        return -1
      }
    })

    let excludeNoDeadline = []
    let noDeadline = []
    let noDates = []
    for (let item of list) {
      if (item.task.deadline) {
        excludeNoDeadline.push(item)
      } else {
        if (item.task.plannedDate) {
          noDeadline.push(item)
        } else {
          noDates.push(item)
        }
      }
    }

    //Sort on deadline
    let filteredList = excludeNoDeadline.sort(function (a: any, b: any) {
      let dateA = new Date(a.task.deadline)
      let dateB = new Date(b.task.deadline)

      if (dateA == null || dateB == null) {
        return 0
      }
      if (dateA > dateB) {
        return 1
      }
      if (dateA < dateB) {
        return -1
      }
    })

    for (let item of noDeadline) {
      if (!item.task.deadline) {
        filteredList.push(item)
      }
    }

    for (let item of noDates) {
      filteredList.push(item)
    }

    return filteredList
  }
}

export function sortSupportEmployeesFirst(employees: Employee[]) {
  let filteredList = []
  for (let employee of employees) {
    if (employee.roles.some((r) => r.type === "SUPPORT")) {
      filteredList.push(employee)
    }
  }

  for (let employee of employees) {
    if (!employee.roles.some((r) => r.type === "SUPPORT")) {
      filteredList.push(employee)
    }
  }

  return filteredList
}

export function sortEmployeesByCompany(employees: any) {
  let sortedList: any[] = []
  const uniqueEmployeeList = new Set()
  const uniqueCompanyList = new Set()
  for (let employee of employees) {
    for (let company of employee.roles) {
      uniqueCompanyList.add(company.company.name)
    }
  }

  uniqueCompanyList.forEach((company: any) => {
    for (let employee of employees) {
      for (let employeeCompany of employee.roles) {
        if (employeeCompany.company.name === company) {
          uniqueEmployeeList.add(employee)
        }
      }
    }
  })

  uniqueEmployeeList.forEach((employee: any) => {
    sortedList.push(employee)
  })

  return sortedList
}
